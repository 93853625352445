body{
    background-color: rgba(11, 39, 72, 1);
    width: 100%;
    height: 100%;
}

.login{
    background-color: rgba(244, 244, 244, 1);
    border-radius: 10px;
    padding: 20px;
    width: fit-content;
    margin: auto;
    text-align: center;
}

.container {
    background:rgba(11, 39, 72, 1);
    padding-top: 10vh;
    padding-bottom: 10vh;
}

.azul{
    color: rgba(28, 64, 192, 1);
    font-weight: 700;
}
.bienvenido{
    font-size:20px
}
input{
    background-color: rgba(217, 217, 217, 1);
    border-radius: 5px;
    border: none;
    width: 100%;
    padding: 5px;
}
input:hover{
    background-color: rgb(160 208 213)
}
.item{
    margin: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

button{
    background-color: rgba(28, 104, 192, 1);
    border-radius: 20px;
    color: white;
    padding: 8px 30px;
    font-weight: 700;
    font-size: 15px;
    border: none;
    margin: 10px;
    cursor:pointer
}





.cambiar-cont{
    text-decoration: none;
    font-size: 10px;
    color: rgba(11, 39, 72, 1);
}

.cambiar-cont:hover{
    color:rgb(28 177 192);
}