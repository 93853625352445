
  .containerBoard {
      margin: auto;
      width: 100%;
      height: 100%;
  }

  .fullscreen {
    background:#f5f5f5
  }

  .fullscreen-button {
    padding: 14px;
    border-radius: 50%;
    position: absolute;
    bottom: 10px;
    left:10px
  }

  .info {
    height: auto;
  }

  .icono {
    height: 83px;
    width: 91px;
  }

  .texto {
    color: #0E3460;
    font-weight: bold;
    font-size: 16px;
  }

  .iconoYHora {
    display: flex;
    justify-content: space-between;
    height: auto;
    align-items: center;
  }

  .hora {
    font-size: 93px;
    color: #1C40C0;
    font-weight: 400;
  }

  h3 {
  font-family: "Roboto";
  font-size: 35px;
  text-align: right;
  margin: 5px 40px 0 0;
}
